var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vote"},[_c('h3',{staticClass:"h3 vote__title"},[_vm._v(_vm._s(_vm.titlePoll))]),_c('ul',{staticClass:"vote__list",class:{'vote__list--voted':_vm.isUserVoted}},[_vm._l((_vm.votes),function(vote){return (!_vm.isUserVoted)?_c('li',{key:vote.id,staticClass:"vote__item",on:{"click":function($event){return _vm.voteHandler(vote.id)}}},[_vm._v(" "+_vm._s(vote.text)+" ")]):_vm._e()}),_vm._l((_vm.votes),function(vote){return (_vm.isUserVoted)?_c('li',{key:vote.id,staticClass:"vote__item vote__item--result",class:{'vote__item--voted':vote.is_voted},on:{"click":function($event){return _vm.voteHandler(vote.id)}}},[_c('div',{staticClass:"vote__item-progress",style:({
                    width: ((_vm.getPercentageWidth(vote.clicked.count)) + "%"),
                    display: vote.clicked.count === 0 ? 'none' : 'flex',
                    background: !vote.is_voted ? '#FFF5E1' : '#FBC04F',
                    borderRadius: _vm.getPercentageWidth(vote.clicked.count) === 100 ? '50px' : '50px 0 0 50px'
                })}),_c('span',{staticClass:"vote__item-name"},[(vote.is_voted)?_c('i',{staticClass:"vote__check-icon"}):_vm._e(),_vm._v(" "+_vm._s(vote.text)+" ")]),_c('span',{staticClass:"vote__item-count"},[_vm._v(_vm._s(vote.clicked.count))]),(vote.clicked.count >= 1 && (_vm.current_user.is_admin || _vm.current_user.is_chief))?_c('v-popover',{staticClass:"vote__popover",attrs:{"trigger":"hover","placement":"right-center","offset":1,"delay":{
                    hide:500
                }}},[_c('div',{staticClass:"tooltip-target vote__popover-target"}),_c('template',{staticClass:"post__advert-content",slot:"popover"},[_c('div',{staticClass:"voted-list"},[_vm._l((vote.clicked.list),function(voted){return _c('router-link',{key:voted.id,staticClass:"voted-list__item",attrs:{"to":voted.user.link,"title":voted.user.short_name}},[_c('img',{staticClass:"voted-list__avatar",attrs:{"src":voted.user.image_zoom_out || _vm.defaultAvatar}})])}),(vote.clicked.count > 4)?_c('a',{staticClass:"voted-list__more",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.votedListOpen(vote.id)}}},[_vm._v(" +"+_vm._s(vote.clicked.count-4)+" ")]):_vm._e()],2)])],2):_vm._e()],1):_vm._e()}),(_vm.votesCount >= 1 && (_vm.current_user.is_admin || _vm.current_user.is_chief))?_c('div',{staticClass:"voted-list__count",on:{"click":_vm.allVotesOpen}},[_vm._v(" "+_vm._s(((_vm.num2str(_vm.votesCount, ['Проголосовал', 'Проголосовало', 'Проголосовали'])) + " " + _vm.votesCount + " " + (_vm.num2str(_vm.votesCount, ['сотрудник', 'сотрудника', 'сотрудников']))))+" ")]):_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }