<template>
    <div class="vote">
        <h3 class="h3 vote__title">{{titlePoll}}</h3>
        <ul class="vote__list" :class="{'vote__list--voted':isUserVoted}">
            <!-- Кнопки с голосами, если пользователь не проголосовал -->
            <li
                v-if="!isUserVoted"
                v-for="vote in votes"
                class="vote__item"
                @click="voteHandler(vote.id)"
                :key="vote.id"
            >
                {{vote.text}}
            </li>
            <!-- Результаты голосования, если пользователь проголосовал -->
            <li
                v-if="isUserVoted"
                v-for="vote in votes"
                class="vote__item vote__item--result"
                :class="{'vote__item--voted':vote.is_voted}"
                @click="voteHandler(vote.id)"
                :key="vote.id"
            >
                <!-- Процентная шкала голосов от общего кол-ва -->
                <div
                    class="vote__item-progress"
                    :style="{
                        width: `${getPercentageWidth(vote.clicked.count)}%`,
                        display: vote.clicked.count === 0 ? 'none' : 'flex',
                        background: !vote.is_voted ? '#FFF5E1' : '#FBC04F',
                        borderRadius: getPercentageWidth(vote.clicked.count) === 100 ? '50px' : '50px 0 0 50px'
                    }"
                />
                <span class="vote__item-name">
                    <i v-if="vote.is_voted" class="vote__check-icon"/>
                    {{vote.text}}
                </span>
                <span class="vote__item-count">{{vote.clicked.count}}</span>
                <v-popover
                    v-if="vote.clicked.count >= 1 && (current_user.is_admin || current_user.is_chief)"
                    trigger="hover"
                    placement="right-center"
                    :offset="1"
                    :delay="{
                        hide:500
                    }"
                    class="vote__popover"
                >
                    <div class="tooltip-target vote__popover-target"/>

                    <template slot="popover" class="post__advert-content">
                        <div
                            class="voted-list"
                        >
                            <router-link
                                v-for="voted of vote.clicked.list"
                                :key="voted.id"
                                :to="voted.user.link"
                                class="voted-list__item"
                                :title="voted.user.short_name"
                            >
                                <img class="voted-list__avatar" :src="voted.user.image_zoom_out || defaultAvatar">
                            </router-link>
                            <a
                                v-if="vote.clicked.count > 4"
                                href="javascript:void(0)"
                                class="voted-list__more"
                                @click="votedListOpen(vote.id)"
                            >
                                +{{vote.clicked.count-4}}
                            </a>
                        </div>
                    </template>
                </v-popover>
            </li>
            <div v-if="votesCount >= 1 && (current_user.is_admin || current_user.is_chief)" class="voted-list__count" @click="allVotesOpen">
                {{
                    `${num2str(votesCount, ['Проголосовал', 'Проголосовало',
                    'Проголосовали'])} ${votesCount} ${num2str(votesCount, ['сотрудник', 'сотрудника',
                    'сотрудников'])}`
                }}
            </div>
        </ul>
    </div>
</template>

<script>
    import session from '@/api/session';
    import {mapState} from "vuex";
    import defaultAvatar from 'assets/img/avatars/default_avatar.jpg';

    export default {
        name: "Vote",
        data() {
            return {
                votes: this.voteList,
                defaultAvatar
            }
        },
        props: {
            titlePoll: {
                type: String,
                required: true
            },
            voteList: {
                type: Array,
                required: true
            },
            postId: {
                type: Number,
                required: true
            }
        },
        methods: {
            async voteHandler(voteId) {
                await session.post(`/api/v1/wall/${this.postId}/vote/${voteId}/`);
                this.updateVotes();
            },
            /*Обновить список голосов после голоса*/
            async updateVotes() {
                const request = session.get(`/api/v1/wall/${this.postId}/`);
                const response = await request;
                this.votes = response.data.votes;
            },
            /*Процентная ширина каждого голоса для шкалы*/
            getPercentageWidth(votes) {
                return Math.round((votes / this.votesCount) * 100);
            },
            votedListOpen(voteId) {
                const UsersModal = () => import(`../../modals/UsersModal`);
                this.$modal.show(UsersModal, {
                    modalTitle: 'Проголосовали:',
                    api: `/api/v1/wall/${this.postId}/vote/${voteId}/`
                }, {
                    name: 'UsersModal',
                    width: '500px',
                    height: '600px',
                })
            },
            allVotesOpen() {
                if (this.votesCount >= 1) {
                    const UsersModal = () => import(`../../modals/UsersModal`);
                    this.$modal.show(UsersModal, {
                        modalTitle: 'Список проголосовавших:',
                        api: `/api/v1/wall/${this.postId}/vote_users/`
                    }, {
                        name: 'UsersModal',
                        width: '500px',
                        height: '600px',
                    })
                }
            },
            /* Склонение числительных для слова "отметка" */
            num2str(n, text_forms) {
                n = Math.abs(n) % 100;
                var n1 = n % 10;

                if (n > 10 && n < 20) {
                    return text_forms[2];
                }

                if (n1 > 1 && n1 < 5) {
                    return text_forms[1];
                }

                if (n1 == 1) {
                    return text_forms[0];
                }

                return text_forms[2];
            }
        },
        computed: {
            ...mapState('default_data', [
                'current_user'
            ]),
            /*Общее кол-во голосов*/
            votesCount() {
                return this.votes.reduce((votesCount, currentVote) => votesCount + currentVote.clicked.count, 0)
            },
            isUserVoted() {
                return this.votes.some(vote => vote.is_voted);
            }
        },
        mounted() {
            this.votes = this.voteList
        }
    }
</script>

<style lang="sass">
    @import '#sass/v-style'

    .vote
        border-top: 1px solid #DBDBDB
        padding: 30px

        &__title
            font-size: 1.125rem
            margin: 0 0 20px 0

        &__list
            width: 100%

        &__item
            position: relative
            width: 60%
            display: flex
            align-items: center
            height: 40px
            border: 1px solid $gold
            border-radius: 50px
            font-size: 1rem
            padding: 0 20px
            cursor: pointer
            overflow: hidden

            &:nth-child(n+2)
                margin-top: 10px

            &:hover
                background: #FFF5E1

                &::before
                    content: ''
                    position: absolute
                    border: 2px solid #fff
                    height: 100%
                    width: 100%
                    left: 0
                    top: 0
                    border-radius: 50px

            &--result
                display: flex
                justify-content: space-between

                &:hover
                    background: none


            &--voted
                .vote__item-name
                font-weight: 600

            &-name
                z-index: 5
                font-size: 1rem
                display: flex
                align-items: center

            &-count
                z-index: 5
                opacity: 0.3

            &-progress
                position: absolute
                z-index: 4
                border-radius: 50px
                left: 0
                width: 0
                height: 100%
                content: ''
                padding: 3px
                border: 2px solid #fff

        &__check-icon
            background-image: $check-icon
            background-size: contain
            background-position: center
            height: 10px
            width: 14px
            margin-right: 10px

        &__popover,
        &__popover-target
            position: absolute
            top: 0
            left: 0
            width: 100%
            height: 100%
            z-index: 50

        &-content
            width: 100%

    .voted-list
        display: flex
        align-items: center
        height: 100%
        &__item
            &:nth-child(n+2)
                margin-left: 5px
        &__avatar
            height: 24px
            width: 24px
            border-radius: 50%
        &__more
            display: flex
            justify-content: center
            align-items: center
            height: 24px
            margin-left: 5px
            color: #000
            border: 1px solid #FBC04F
            border-radius: 50px
            padding: 0 5px
        &__count
            color: $text-gray
            margin-top: 20px
            cursor: pointer


    .vote .trigger
        width: 100% !important
    @media (max-width: 768px)
        .vote__item
            width: 100%
</style>
